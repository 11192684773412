import React from 'react'

export type ThemeOption = 'LIGHT' | 'DARK' | 'NATURE'

interface Settings {
	compact?: boolean
	direction?: 'ltr' | 'rtl'
	responsiveFontSizes?: boolean
	roundedCorners?: boolean
	theme?: ThemeOption
}

export interface SettingsContextValue {
	settings: Settings
}

const initialSettings: Settings = {
	compact: true,
	direction: 'ltr',
	responsiveFontSizes: true,
	roundedCorners: false,
	theme: 'LIGHT'
}

const SettingsContext = React.createContext<SettingsContextValue | null>(null)

export type SettingProviderProps = React.PropsWithChildren<{}>

export const SettingsProvider = ({ children }: SettingProviderProps) => {
	return (
		<SettingsContext.Provider
			value={{
				settings: initialSettings
			}}
		>
			{children}
		</SettingsContext.Provider>
	)
}

export const useSettings = () => {
	const context = React.useContext(SettingsContext)
	if (!context) {
		throw new Error('useSettings requires <SettingsProvider />')
	}
	return context
}
