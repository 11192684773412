import { Link as RouterLink } from 'react-router-dom'
import { Box, List, ListProps, ListItem, ListItemProps, ListSubheader, Button } from '@material-ui/core'

interface NavSectionProps extends ListProps {
	title: string
}

interface NavSectionItemProps extends ListItemProps {
	title: string
}

interface NavSection {
	(props: NavSectionProps): JSX.Element
	Item: (props: NavSectionItemProps) => JSX.Element
}

const NavSection: NavSection = ({ title, ...props }) => {
	return (
		<List
			subheader={
				<ListSubheader
					disableGutters
					disableSticky
					sx={{
						color: 'text.primary',
						fontSize: '0.75rem',
						lineHeight: 2.5,
						fontWeight: 700,
						textTransform: 'uppercase'
					}}
				>
					{title}
				</ListSubheader>
			}
			{...props}
		/>
	)
}

NavSection.Item = ({ title }) => {
	return (
		<ListItem
			disableGutters
			sx={{
				display: 'flex',
				py: 0
			}}
		>
			<Button
				component={RouterLink}
				sx={{
					color: 'text.secondary',
					fontWeight: 'fontWeightMedium',
					justifyContent: 'flex-start',
					textAlign: 'left',
					p: '12px',
					textTransform: 'none',
					width: '100%'
				}}
				variant="text"
				to="/dashboard"
			>
				<Box sx={{ flexGrow: 1 }}>{title}</Box>
			</Button>
		</ListItem>
	)
}

export default NavSection
