import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Avatar, Box, Button, Divider, Drawer, Typography, useTheme } from '@settled/ui'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NavSection from './NavSection'

interface DashboardSidebarProps {
	onMobileClose: () => void
	openMobile: boolean
}

const user = {
	id: '5e86809283e28b96d2d38537',
	avatar: require('./avatar-tate.png'),
	email: 'tateobrian@gmail.com',
	name: `Tate O'Brian`,
	password: 'Password123!',
	plan: 'Premium'
}

const SideBar = ({ onMobileClose, openMobile }: DashboardSidebarProps) => {
	const location = useLocation()
	const { breakpoints } = useTheme()
	const lgUp = useMediaQuery(breakpoints.up('lg'))

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose()
		}
	}, [location.pathname])

	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%'
			}}
		>
			<Box
				sx={{
					display: {
						lg: 'none',
						xs: 'flex'
					},
					justifyContent: 'center',
					p: 2
				}}
			>
				<RouterLink to="/">[ Logo ]</RouterLink>
			</Box>
			<Box sx={{ p: 2 }}>
				<Box
					sx={{
						alignItems: 'center',
						backgroundColor: 'background.default',
						borderRadius: 1,
						display: 'flex',
						overflow: 'hidden',
						p: 2
					}}
				>
					<Avatar
						src={user.avatar}
						sx={{
							height: 48,
							width: 48
						}}
					/>
					<Box sx={{ ml: 2 }}>
						<Typography color="textPrimary" variant="subtitle2">
							{user.name}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							Your plan: {user.plan}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Divider />
			<Box sx={{ p: 2 }}>
				<NavSection
					title="General"
					sx={{
						'& + &': {
							mt: 3
						}
					}}
				>
					<NavSection.Item title="Dashboard" />
					<NavSection.Item title="Users" />
					<NavSection.Item title="Transactions" />
					<NavSection.Item title="Subscriptions" />
					<NavSection.Item title="Calendar" />
					<NavSection.Item title="Agenda" />
				</NavSection>
				<NavSection
					title="Account"
					sx={{
						'& + &': {
							mt: 3
						}
					}}
				>
					<NavSection.Item title="Profile" />
					<NavSection.Item title="Settings" />
					<NavSection.Item title="Bank Accounts" />
					<NavSection.Item title="Log Out" />
				</NavSection>
			</Box>
			<Divider />
			<Box sx={{ p: 2 }}>
				<Typography color="textPrimary" variant="subtitle2">
					Need Help?
				</Typography>
				<Typography color="textSecondary" variant="body2">
					Check our docs
				</Typography>
				<Button color="primary" component={RouterLink} fullWidth sx={{ mt: 2 }} to="/docs" variant="contained">
					Documentation
				</Button>
			</Box>
		</Box>
	)

	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: 'background.paper',
						height: 'calc(100% - 64px) !important',
						top: '64px !Important',
						width: 280
					}
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		)
	}

	return (
		<Drawer
			anchor="left"
			onClose={onMobileClose}
			open={openMobile}
			PaperProps={{
				sx: {
					backgroundColor: 'background.paper',
					width: 280
				}
			}}
			variant="temporary"
		>
			{content}
		</Drawer>
	)
}

export default SideBar
