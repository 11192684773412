import React from 'react'
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { useSettings } from 'src/components/SettingsProvider'
import { createCustomTheme } from 'src/theme'
export { useTheme } from '@material-ui/core/styles'

type ThemeProviderProps = React.PropsWithChildren<{}>

const ThemeProvider = ({ children }: ThemeProviderProps) => {
	const { settings } = useSettings()

	const theme = createCustomTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		roundedCorners: settings.roundedCorners,
		theme: settings.theme
	})

	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</MuiThemeProvider>
	)
}

export default ThemeProvider
