import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from './NavBar'
import SideBar from './SideBar'

import { Box, useTheme } from '@settled/ui'

const DashboardLayout = () => {
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false)
	const { breakpoints } = useTheme()

	return (
		<Box
			sx={{
				backgroundColor: (theme) => theme.palette.background.default,
				display: 'flex',
				height: '100%',
				overflow: 'hidden',
				width: '100%'
			}}
		>
			<NavBar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
			<SideBar onMobileClose={(): void => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
			<Box
				sx={{
					display: 'flex',
					flex: '1 1 auto',
					overflow: 'hidden',
					paddingTop: '64px',
					[breakpoints.up('lg')]: {
						paddingLeft: '280px'
					}
				}}
			>
				<Box sx={{ display: 'flex', flex: '1 1 auto', overflow: 'hidden' }}>
					<Box
						sx={{
							flex: '1 1 auto',
							height: '100%',
							overflow: 'auto',
							position: 'relative',
							WebkitOverflowScrolling: 'touch'
						}}
					>
						<Outlet />
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default DashboardLayout
