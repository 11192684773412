import { Box, Button, Container, Grid, Typography } from '@settled/ui'
import TotalTransactions from 'src/components/OverviewTotalTransactions'
import TotalBalances from 'src/components/OverviewTotalBalance'
import OverviewLatestTransactions from 'src/components/OverviewLatestTransactions'

function Dashboard() {
	return (
		<Box
			sx={{
				backgroundColor: 'background.default',
				minHeight: '100%',
				py: 8
			}}
		>
			<Container maxWidth="xl">
				<Grid container spacing={3}>
					<Grid alignItems="center" container justifyContent="space-between" spacing={3} item xs={12}>
						<Grid item>
							<Typography color="textSecondary" variant="overline">
								Overview
							</Typography>
							<Typography color="textPrimary" variant="h5">
								Good Morning, Tate
							</Typography>
							<Typography color="textSecondary" variant="subtitle2">
								Here&apos;s what&apos;s happening with your business today
							</Typography>
						</Grid>
						<Grid item>
							<Button color="primary" variant="contained">
								New Transaction
							</Button>
						</Grid>
					</Grid>
					<Grid item md={8} xs={12}>
						<TotalTransactions />
					</Grid>
					<Grid item md={4} xs={12}>
						<TotalBalances />
					</Grid>
					<Grid item xs={12}>
						<OverviewLatestTransactions />
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default Dashboard
