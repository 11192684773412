import PerfectScrollbar from 'react-perfect-scrollbar'
import type { ScrollBarProps as PerfectScrollbarProps } from 'react-perfect-scrollbar'
import { Box } from '@material-ui/core'
import { useTheme } from '@settled/ui'
import useMediaQuery from '@material-ui/core/useMediaQuery'

function Scrollbar(props: PerfectScrollbarProps) {
	const { breakpoints } = useTheme()
	const isMobile = useMediaQuery(breakpoints.down('md'))
	if (isMobile) {
		return <Box sx={{ overflowX: 'auto' }}>{props.children}</Box>
	}
	return <PerfectScrollbar {...props} />
}

export default Scrollbar
