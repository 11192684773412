import React, { StrictMode } from 'react'
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider'
import { SettingsProvider } from 'src/components/SettingsProvider'
import ThemeProvider from 'src/components/ThemeProvider'

export type BaseProviderProps = React.PropsWithChildren<{}>

export const BaseProvider = ({ children }: BaseProviderProps) => (
	<StrictMode>
		<StyledEngineProvider injectFirst>
			<SettingsProvider>
				<ThemeProvider>{children}</ThemeProvider>
			</SettingsProvider>
		</StyledEngineProvider>
	</StrictMode>
)
