import 'regenerator-runtime/runtime'
import { render } from 'react-dom'
import { BaseProvider } from '@settled/ui'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import DashboardLayout from './components/DashboardLayout'

import Dashboard from './routes/dashboard'

const App = () => {
	return (
		<BaseProvider>
			<BrowserRouter>
				<Routes>
					<Route path="*" element={<DashboardLayout />}>
						<Route path="/" element={<Navigate to="/dashboard" />} />
						<Route path="/dashboard" element={<Dashboard />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</BaseProvider>
	)
}

render(<App />, document.getElementById('root'))
