import { Link as RouterLink } from 'react-router-dom'
import { AppBar, AppBarProps, Box, IconButton, Toolbar, useTheme } from '@settled/ui'
import Logo from 'src/components/Logo'

interface DashboardNavbarProps extends AppBarProps {
	onSidebarMobileOpen?: () => void
}

const DashboardNavbar = ({ onSidebarMobileOpen, ...props }: DashboardNavbarProps) => {
	const theme = useTheme()
	return (
		<AppBar
			sx={{
				backgroundColor: theme.palette.primary.main,
				boxShadow: 'none',
				color: theme.palette.primary.contrastText,
				zIndex: theme.zIndex.drawer + 100
			}}
			{...props}
		>
			<Toolbar sx={{ minHeight: 64 }}>
				<IconButton
					color="inherit"
					onClick={onSidebarMobileOpen}
					sx={{
						display: {
							lg: 'none'
						}
					}}
				>
					Menu
				</IconButton>
				<RouterLink to="/">
					<Logo />
				</RouterLink>
				<Box
					sx={{
						flexGrow: 1,
						ml: 2
					}}
				/>
			</Toolbar>
		</AppBar>
	)
}

export default DashboardNavbar
