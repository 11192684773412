import type { FC } from 'react'
import numeral from 'numeral'
import { Box, Card, CardHeader, Table, TableBody, TableCell, TableRow, Typography } from '@settled/ui'
import dayjs from 'dayjs'

interface Transaction {
	id: string
	amount: number
	currency: string
	date: Date
	sender: string
	type: string
}

const transactions: Transaction[] = [
	{
		id: 'd46800328cd510a668253b45',
		amount: 500,
		currency: 'usd',
		date: new Date(),
		sender: 'Collin Morikawa',
		type: 'receive'
	},
	{
		id: 'b4b19b21656e44b487441c50',
		amount: 250,
		currency: 'usd',
		date: dayjs().subtract(1, 'day').toDate(),
		sender: 'Jordan Spieth',
		type: 'receive'
	},
	{
		id: '56c09ad91f6d44cb313397db',
		amount: 300,
		currency: 'usd',
		date: dayjs().subtract(2, 'days').toDate(),
		sender: 'Patrick Cantlay',
		type: 'receive'
	},
	{
		id: 'aaeb96c5a131a55d9623f44d',
		amount: 100,
		currency: 'usd',
		date: dayjs().subtract(3, 'days').toDate(),
		sender: 'Harris English',
		type: 'receive'
	}
]

const OverviewLatestTransactions: FC = (props) => (
	<Card {...props}>
		<CardHeader title="Latest Transactions" />
		<Table>
			<TableBody>
				{transactions.map((transaction) => (
					<TableRow
						key={transaction.id}
						sx={{
							'&:last-child td': {
								border: 0
							}
						}}
					>
						<TableCell width={100}>
							<Box sx={{ p: 1 }}>
								<Typography align="center" color="textSecondary" variant="subtitle2">
									{dayjs(transaction.date).format('MMM').toUpperCase()}
								</Typography>
								<Typography align="center" color="textSecondary" variant="h6">
									{dayjs(transaction.date).format('D')}
								</Typography>
							</Box>
						</TableCell>
						<TableCell>
							<div>
								<Typography color="textPrimary" variant="subtitle2">
									{transaction.sender}
								</Typography>
								<Typography color="textSecondary" variant="body2">
									{transaction.type === 'receive' ? 'Payment received' : 'Payment sent'}
								</Typography>
							</div>
						</TableCell>
						<TableCell align="right">
							<Typography
								color={transaction.type === 'receive' ? 'success.main' : 'error.main'}
								variant="subtitle2"
							>
								{transaction.type === 'receive' ? '+' : '-'}{' '}
								{numeral(transaction.amount).format('$0,0.00')}
							</Typography>
							<Typography color="textSecondary" variant="body2">
								{transaction.currency.toUpperCase()}
							</Typography>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</Card>
)

export default OverviewLatestTransactions
